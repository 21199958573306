main {
  padding: 1rem 0;
  margin: 0 auto;
}

@media screen and (min-width: _breakpoint(step-one)) {
  main {
    padding: 1rem;
    margin: 0 auto;
  }
}