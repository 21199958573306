footer {
  position: fixed;
  bottom: 0;
  font-size: .85rem;
  width: 100%;
  padding-bottom: 1rem;
  height: 2rem;
  color: white;
  text-align: center;
  margin: 0;

  a {
    margin-left: 2rem;
    color: #0657F9;
    font-weight: 500;
    font-size: .9rem;
    text-decoration: none;
    &:first-child {
      margin-left: 0;
    }
  }
}
