.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.margin-top-1 {
  margin-top: 1rem;
}
.margin-top-2 {
  margin-top: 2rem;
}
.margin-bottom-1 {
  margin-bottom: 1rem;
}
.margin-bottom-2 {
  margin-bottom: 2rem;
}
.align-center {
  text-align: center;
}
.strip-padding {
  padding: 0;
}

.strip-padding-left {
  padding-left: 0 !important;
}
.strip-padding-right {
  padding-right: 0 !important;
}

.margin-auto {
  margin: 0 auto;
}

.text-align-center {
  text-align: center;
}

.visibility-hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

.height-100 {
  height: 100%;
}
