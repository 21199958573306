@import "lib/vars";
@import "lib/functions";
@import "flex";
@import "footer";
@import "image";
@import "main";
@import "markup";

body {
  background-color: rgb(32, 31, 35);
  font-size: 16px;
  color: white;
}

header {
  padding: 1rem;
}

.logo {
  width: 10rem;
  text-align: left;
}

.vertical-center-container {
  display: flex;
  align-items: center;
  height: calc(100vh - 4rem);
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 415px) {
  .vertical-center-container {
    height: calc(100% - 4rem);
    position:fixed;
    top:0;
    bottom:0;
    text-align:center;
    width: 100%;
  }
}

.text-large {
  font-size: 4rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.yearn-blue {
  color: rgb(6, 87, 249);
}

.blue-pill-blue {
  color: rgb(78, 105, 169);
}

.blue-pill-black {
  color: rgb(32, 31, 35);
}

a {
  color: white;
  text-decoration: underline;
}

p, a {
  font-size: 2rem;
  margin: 0;
  padding: 0;
  text-transform: lowercase;
}

.icon-link {
  span {display: none}
  svg {
    background-color: white;
    background-clip: padding-box;
    border-radius: 50%;
    outline: 1px solid #0657F9;
    outline-offset: -1px;
  }
}

@media screen and (max-width: 415px) {
  .icon-link {
    svg {
      background-color: unset;
      background-clip: unset;
      border-radius: unset;
      outline: unset;
      outline-offset: unset;
    }
  }
}

.text-white {
  color: white;
}

.text-transparent {
  color: transparent;
}